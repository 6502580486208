<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :max-width="$vuetify.breakpoint.xsOnly ? '100%' : '900px'"
    persistent
    scrollable
  >
    <v-card flat>
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Registro de Empresa</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <!-- <v-card-subtitle class="pa-0">
          <v-stepper-header flat>
            <v-stepper-step :complete="e6 > 1" step="1" :editable="isStepComplete[0]">
              Contacto
            </v-stepper-step>

            <v-stepper-step :complete="e6 > 2" step="2" :editable="isStepComplete[1]">
              Rut / Nit / Cédula
            </v-stepper-step>

            <v-stepper-step :complete="e6 > 3" step="3" :editable="isStepComplete[2]">
              Crear Cuenta
            </v-stepper-step>
          </v-stepper-header>
        </v-card-subtitle> -->
      <v-card-text class="d-flex justify-center align-center" style="height: 100vh">
        <v-stepper v-model="e6" width="100%">
          <v-stepper-content step="1" he>
            <v-form
              ref="form1"
              class="pa-3"
              v-model="valid"
              @submit.prevent="sendPhoneVerification"
            >
              <v-row>
                <v-col cols="12">
                  <h2 color="secondary">Nombre del Representante</h2>
                  <v-text-field
                    large
                    class="singin-input"
                    v-model="company.representativeName"
                    :rules="[(v) => !!v || 'Campo requerido']"
                    @input="saveState"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h2>Correo Electrónico</h2>
                  <v-text-field
                    large
                    class="singin-input"
                    v-model="company.email"
                    :rules="emailRules"
                    @input="saveState"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h2>Número de Teléfono</h2>
                  <v-text-field
                    large
                    class="singin-input"
                    v-model="company.phone"
                    :rules="phoneRules"
                    @input="formatPhoneNumber"
                    @keypress="onlyNumbers"
                    type="tel"
                  >
                    <template v-slot:append>
                      <v-btn
                        color="primary"
                        :disabled="!valid || phoneVerificationSent"
                        :loading="isVerifyingPhone"
                        @click="sendPhoneVerification"
                        small
                      >
                        SMS
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-otp-input
                    type="number"
                    ref="otpInput"
                    v-if="phoneVerificationSent"
                    v-model="phonePin"
                    length="6"
                    @finish="verifyPhonePin"
                    class="mt-4"
                    flat
                  ></v-otp-input>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card flat>
              <v-card-text>
                <v-form ref="form3" v-model="valid" @submit.prevent="verifyDocument">
                  <v-row>
                    <v-col cols="12">
                      <h2>Validación de RUT / NIT / CC</h2>
                      <v-text-field
                        class="singin-input"
                        v-model="company.document"
                        :rules="documentRules"
                        @input="saveState"
                        type="number"
                        large
                      >
                        <template v-slot:append>
                          <v-btn
                            text
                            color="secondary"
                            @click="verifyDocument"
                            block
                            :loading="isVerifyingDocument"
                          >
                            Validar
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <h2>Nombre Registrado</h2>
                      <v-text-field
                        class="singin-input"
                        v-if="documentVerified"
                        v-model="company.documentName"
                        large
                        @input="saveState"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <h2>Correo Electrónico Registrado</h2>
                      <v-text-field
                        class="singin-input"
                        v-if="documentVerified"
                        v-model="company.documentEmail"
                        :rules="emailRules"
                        @input="saveState"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" @click="prevStep">Anterior</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="secondary"
                  @click="nextStep"
                  :disabled="!documentVerified"
                  >Siguiente</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card flat>
              <v-card-text>
                <v-form ref="form4" v-model="valid" @submit.prevent="submitRegistration">
                  <v-row>
                    <v-col cols="12">
                      <h2>Contraseña</h2>
                      <v-text-field
                        v-model="company.password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        :rules="passwordRules"
                        @input="saveState"
                        @click:append="showPassword = !showPassword"
                        hide-details="auto"
                        class="singin-input"
                      ></v-text-field>
                      <v-divider></v-divider>
                      <v-progress-linear
                        :value="passwordStrength"
                        :color="passwordStrengthColor"
                        height="10"
                        class="mb-4 mt-6"
                      ></v-progress-linear>
                    </v-col>
                    <v-col cols="12">
                      <h2>Confirmar Contraseña</h2>
                      <v-text-field
                        v-model="confirmPassword"
                        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showConfirmPassword ? 'text' : 'password'"
                        :rules="confirmPasswordRules"
                        @input="saveState"
                        hide-details="auto"
                        @click:append="showConfirmPassword = !showConfirmPassword"
                        class="singin-input"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="showSummary" :disabled="!valid">
                  Revisar y Enviar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>

      <v-card-actions>
        <v-btn color="secondary" @click="resetForm">Reset</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="submitRegistration" :disabled="!allFieldsValid"
          >Enviar</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- Summary Dialog -->
    <v-dialog v-model="summaryDialog" max-width="500px">
      <v-card>
        <v-card-title>Resumen de Registro</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="(value, key) in companySummary" :key="key">
              <v-list-item-content>
                <v-list-item-title>{{ key }}</v-list-item-title>
                <v-list-item-subtitle>{{ value }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="summaryDialog = false">Editar</v-btn>
          <v-btn color="primary" @click="submitRegistration" :disabled="!allFieldsValid">
            Confirmar y Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Info Dialog -->
    <v-dialog v-model="dialogVisible" max-width="400px">
      <v-card>
        <v-card-title class="headline">Información</v-card-title>
        <v-card-text>{{ dialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogVisible = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { webserver } from "../services/webserver.js";
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      weblogo: null,
      summaryDialog: false,
      dialogMessage: "",
      dialogVisible: false,
      e6: parseInt(localStorage.getItem("step")) || 1,
      valid: false,
      phonePin: "",
      phoneVerified: false,
      emailPin: "",
      emailVerified: false,
      documentVerified: false,
      confirmPassword: "",
      company: {
        phone: "",
        email: "",
        type_id: "",
        document: "",
        documentName: "",
        representativeName: "",
        password: "",
      },

      phoneVerificationSent: false,
      emailVerificationSent: false,
      showPassword: false,
      showConfirmPassword: false,
      isVerifyingPhone: false,
      isVerifyingEmail: false,
      isVerifyingDocument: false,
      stepTitles: ["Contacto", "Rut / Nit / Cédula", "Crear Cuenta"],
      phoneRules: [
        (v) => !!v || "El número de teléfono es requerido",
        (v) => v.replace(/\D/g, "").length === 10 || "El número debe tener 10 dígitos",
      ],
      emailRules: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El correo electrónico debe ser válido",
      ],
      documentRules: [
        (v) => !!v || "El documento es requerido",
        (v) => /^\d+$/.test(v) || "El documento debe contener solo números",
      ],
      passwordRules: [
        (v) => !!v || "La contraseña es requerida",
        (v) => v.length >= 8 || "La contraseña debe tener al menos 8 caracteres",
        (v) =>
          /[A-Z]/.test(v) || "La contraseña debe contener al menos una letra mayúscula",
        (v) =>
          /[a-z]/.test(v) || "La contraseña debe contener al menos una letra minúscula",
        (v) => /[0-9]/.test(v) || "La contraseña debe contener al menos un número",
        (v) =>
          /[!@#$%^&*()]/.test(v) ||
          "La contraseña debe contener al menos un carácter especial",
      ],
      isStepComplete: [false, false, false, false],
      generatedPhonePin: null,
      generatedEmailPin: null,
    };
  },
  computed: {
    dialogFullscreen() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    dialogMaxWidth() {
      return this.$vuetify.breakpoint.xsOnly ? "100%" : "800px";
    },
    passwordStrength() {
      let strength = 0;
      const password = this.company.password;
      if (password.length >= 8) strength += 20;
      if (/[A-Z]/.test(password)) strength += 20;
      if (/[a-z]/.test(password)) strength += 20;
      if (/[0-9]/.test(password)) strength += 20;
      if (/[!@#$%^&*()]/.test(password)) strength += 20;
      return strength;
    },
    passwordStrengthColor() {
      if (this.passwordStrength < 40) return "red";
      if (this.passwordStrength < 70) return "orange";
      return "green";
    },
    confirmPasswordRules() {
      return [
        (v) => !!v || "La confirmación de contraseña es requerida",
        (v) => v === this.company.password || "Las contraseñas no coinciden",
      ];
    },
    companySummary() {
      return {
        Teléfono: this.company.phone,
        "Correo Electrónico": this.company.email,
        Documento: this.company.document,
        "Nombre Registrado": this.company.documentName,
        "Nombre del Representante": this.company.representativeName,
      };
    },
    allFieldsValid() {
      return (
        this.company.phone &&
        this.company.email &&
        this.company.document &&
        this.company.documentName &&
        this.company.representativeName &&
        this.company.password &&
        this.confirmPassword &&
        this.company.password === this.confirmPassword
      );
    },
  },
  methods: {
    close() {
      this.$emit("close", true);
    },
    saveState() {
      localStorage.setItem("step", this.e6);
      localStorage.setItem("company", JSON.stringify(this.company));
    },
    formatPhoneNumber() {
      let number = this.company.phone.replace(/\D/g, "");
      if (number.length > 10) {
        number = number.slice(0, 10);
      }
      if (number.length > 6) {
        this.company.phone = `(${number.slice(0, 3)}) ${number.slice(
          3,
          6
        )}-${number.slice(6)}`;
      } else if (number.length > 3) {
        this.company.phone = `(${number.slice(0, 3)}) ${number.slice(3)}`;
      } else {
        this.company.phone = number;
      }
    },
    onlyNumbers(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        event.preventDefault();
      }
    },
    // sendPhoneVerification() {
    //   this.isVerifyingPhone = true;
    //   setTimeout(() => {
    //     this.phoneVerificationSent = true;
    //     this.isVerifyingPhone = false;
    //     this.dialogMessage = "SMS enviado con éxito";
    //     this.dialogVisible = true;
    //     this.saveState();
    //   }, 2000);
    // },

    // Include similar methods for email verification, document verification, etc.
    nextStep() {
      if (this.e6 < 4) this.e6++;
      this.saveState();
    },
    prevStep() {
      if (this.e6 > 1) this.e6--;
      this.saveState();
    },
    showSummary() {
      this.summaryDialog = true;
    },
    async submitRegistration() {
      if (!this.allFieldsValid) {
        this.dialogMessage = "Por favor complete todos los campos";
        this.dialogVisible = true;
        return;
      }

      try {
        this.loading = true;
        this.errorMessage = "";
        const result = await this.callWebServer("createCompany", this.company);

        if (result.error) {
          this.loading = false;
          this.errorMessage = result.error;
          throw new Error(result.error);
        } else {
          this.dialogMessage = "Registro completado con éxito";
          this.dialogVisible = true;
          this.dialog = false;
          this.summaryDialog = false;
          localStorage.clear();
          this.resetForm();
        }
      } catch (error) {
        console.error("Error adding message:", error);
        this.errorMessage = "Failed to add message. Please try again.";
        throw error; // Re-throw if you want calling code to handle it
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.e6 = 1;
      this.phonePin = "";
      this.phoneVerified = false;
      this.emailPin = "";
      this.emailVerified = false;
      this.documentVerified = false;
      this.confirmPassword = "";
      this.company = {
        phone: "",
        email: "",
        document: "",
        type_id: "",
        documentName: "",
        documentEmail: "",
        representativeName: "",
        representativeEmail: "",
        password: "",
      };
      this.phoneVerificationSent = false;
      this.emailVerificationSent = false;
    },

    generatePin() {
      return Math.floor(100000 + Math.random() * 900000).toString();
    },
    async sendPhoneVerification() {
      this.isVerifyingPhone = true;
      this.generatedPhonePin = this.generatePin();
      var phone = this.company.phone.replace(/\D/g, "");
      var messageData = {
        phoneNumber: phone,
        msg: "Su PIN de verificación es: " + this.generatedPhonePin,
      };
      try {
        this.loading = true;
        this.errorMessage = "";
        const result = await this.callWebServer("sendSMS", messageData);
        if (result.error) {
          this.errorMessage = result.error;
          throw new Error(result.error);
        } else {
          this.phoneVerificationSent = true;
          this.isVerifyingPhone = false;

          this.dialogMessage = `SMS enviado con éxito.`;
          this.dialogVisible = true;
          console.log("Message added successfully:", result);
          return result;
        }
      } catch (error) {
        console.error("Error adding message:", error);
        this.errorMessage = "Failed to add message. Please try again.";
        throw error; // Re-throw if you want calling code to handle it
      } finally {
        this.loading = false;
      }
    },
    callWebServer(method, data) {
      return new Promise((resolve, reject) => {
        webserver(
          method,
          data,
          function (response) {
            resolve(response);
          },
          function (error) {
            reject(error);
          }
        );
      });
    },
    verifyPhonePin() {
      if (this.phonePin === this.generatedPhonePin) {
        this.phoneVerified = true;
        this.phonePin = "";
        this.isStepComplete[0] = true;
        this.saveState();
        this.nextStep();
        // this.dialogMessage = "Número de teléfono verificado correctamente";
        // this.dialogVisible = true;
      } else {
        this.dialogMessage = "PIN incorrecto. Por favor, intente nuevamente.";
        this.dialogVisible = true;
      }
    },
    async send_email(to, subject, message) {
      var messageData = {
        email: to,
        subject: subject,
        msg: message,
      };

      try {
        this.loading = true;
        this.errorMessage = "";
        const result = await this.callWebServer("send_email", messageData);
        if (result.error) {
          this.loading = false;
          this.emailVerified = false;
          this.errorMessage = result.error;
          throw new Error(result.error);
        } else {
          this.emailVerificationSent = true;
          this.isVerifyingEmail = false;
          this.dialogMessage = "Correo enviado con éxito";
          this.dialogVisible = true;
          this.saveState();
        }
      } catch (error) {
        console.error("Error adding message:", error);
        this.errorMessage = "Failed to add message. Please try again.";
        throw error; // Re-throw if you want calling code to handle it
      } finally {
        this.loading = false;
      }
    },
    async sendMessage(envelope) {
      try {
        const result = await this.callWebServer("send_email", envelope);
        if (result.error) {
          throw new Error(result.error);
        }
        return result;
      } catch (error) {
        console.error(`Error al enviar email:`, error);
        throw error;
      }
    },
    async verifyDocument() {
      this.isVerifyingDocument = true;
      var messageData = {
        nit: this.company.document,
      };
      //console.log("Message data:", messageData);
      try {
        this.loading = true;
        this.errorMessage = "";
        const result = await this.callWebServer("get_rut", messageData);

        if (result.error) {
          this.loading = false;
          this.documentVerified = false;
          this.errorMessage = result.error;
          throw new Error(result.error);
        } else {
          this.documentVerified = true;
          if (result.businessName) {
            this.company.documentName = result.businessName;
            this.company.type_id = "6";
            //this.company.documentEmail = result.email;
          } else {
            if (result.first_name) {
              this.company.documentName =
                result.first_name + " " + result.surname + " " + result.second_surname;
              this.company.type_id = "3";
            }
          }

          this.isVerifyingDocument = false;
          this.dialogMessage = "Documento verificado";
          this.dialogVisible = true;
          this.saveState();
        }
      } catch (error) {
        console.error("Error adding message:", error);
        this.errorMessage = "Failed to add message. Please try again.";
        throw error; // Re-throw if you want calling code to handle it
      } finally {
        this.loading = false;
      }
    },
    nextStep() {
      if (this.e6 < 4) this.e6++;
      this.saveState();
    },
    prevStep() {
      if (this.e6 > 1) this.e6--;
      this.saveState();
    },
    showSummary() {
      this.summaryDialog = true;
    },
    // submitRegistration() {
    //   if (!this.allFieldsValid) {
    //     this.dialogMessage = "Por favor complete todos los campos";
    //     this.dialogVisible = true;
    //     return;
    //   }
    //   var userData = {
    //     name: this.company.representativeName,
    //     phone: this.company.phone,
    //     email: this.company.email,
    //     store_id: this.company.document,
    //     store_name: this.company.documentName,
    //     password: this.company.password,
    //   };

    //   // console.log("Datos de registro:", response);
    //   // this.dialogMessage = "Registro completado con éxito";
    //   // this.dialogVisible = true;
    //   // this.dialog = false;
    //   // this.summaryDialog = false;
    //   //localStorage.clear();
    //   //this.resetForm();
    // },

    generateHtml(userData) {
      return `
        <!DOCTYPE html>
        <html lang="es">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Bienvenido a AI-POS</title>
          <style>
            body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
            .container { max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd; border-radius: 5px; }
            .header { background-color: #4CAF50; color: white; text-align: center; padding: 10px; border-radius: 5px 5px 0 0; }
            .content { padding: 20px; }
            .footer { background-color: #f1f1f1; padding: 10px; text-align: center; font-size: 0.8em; border-radius: 0 0 5px 5px; }
          </style>
        </head>
        <body>
          <div class="container">
            <div class="header">
              <h1>¡Bienvenido a AI-POS!</h1>
            </div>
            <div class="content">
              <p>Estimado/a <strong>${userData.representativeName}</strong>,</p>
              <p>Nos complace informarle que su registro en AI-POS ha sido completado con éxito. A continuación, encontrará los detalles de su cuenta:</p>
              <ul>
                <li><strong>Nombre:</strong> ${userData.documentName}</li>
                <li><strong>Documento:</strong> ${userData.document}</li>
                <li><strong>Correo electrónico:</strong> ${userData.email}</li>
                <li><strong>Teléfono:</strong> ${userData.phone}</li>
              </ul>
              <p>Su cuenta está ahora activa y puede acceder a nuestro sistema utilizando su correo electrónico y la contraseña que estableció durante el registro.</p>
              <p>Si tiene alguna pregunta o necesita asistencia, no dude en contactarnos.</p>
              <p>¡Gracias por unirse a AI-POS! Esperamos que disfrute de nuestros servicios.</p>
            </div>
            <div class="footer">
              <p>Este es un correo electrónico automático. Por favor, no responda a este mensaje.</p>
            </div>
          </div>
        </body>
        </html>
      `;
    },
  },
  mounted() {
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    this.weblogo = `./sites/${root}/logo.webp`;
    const savedCompany = localStorage.getItem("company");
    if (savedCompany) {
      this.company = JSON.parse(savedCompany);
    }
  },
};
</script>

<style scoped>
/* Estilos generales */
.signin-card {
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
}

/* Tipografía */
.v-card__title {
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.5px;
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #333;
}

/* Inputs */
.singin-input {
  font-size: 1.2rem;
}

.v-text-field--outlined fieldset {
  border-radius: 12px;
}

.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  border-color: #e0e0e0;
}

/* Botones */
.v-btn {
  text-transform: none;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.v-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* Stepper */
.v-stepper__step__step {
  font-size: 1.2rem;
  font-weight: 600;
}

.v-stepper__label {
  font-size: 1rem;
  font-weight: 500;
}

/* Animaciones */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-out;
}

/* Responsive */
@media (max-width: 600px) {
  .v-card__title {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  .singin-input {
    font-size: 1rem;
  }
}
</style>

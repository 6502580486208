let printerDevice = null;
let printerServer = null;
let printerCharacteristic = null;

// UUIDs conocidos
const PRINTER_SERVICE_UUID = '000018f0-0000-1000-8000-00805f9b34fb';
const PRINTER_CHARACTERISTIC_UUID = '00002af1-0000-1000-8000-00805f9b34fb';
const PRINTER_CONTROL_CHARACTERISTIC_UUID = '00002af0-0000-1000-8000-00805f9b34fb';

async function selectPrinter() {
  try {
    if (!navigator.bluetooth) {
      throw new Error('Web Bluetooth API no está disponible en este navegador.');
    }

    const options = {
      acceptAllDevices: true,
      optionalServices: [
        PRINTER_SERVICE_UUID,
        'generic_access',
        'device_information'
      ]
    };

    console.log('Buscando dispositivos Bluetooth...');
    printerDevice = await navigator.bluetooth.requestDevice(options);
    console.log('Dispositivo seleccionado:', printerDevice.name);

    printerDevice.addEventListener('gattserverdisconnected', onDisconnected);

    const connected = await connectToDevice();
    if (connected) {
      localStorage.setItem('lastUsedPrinter', printerDevice.name);
      console.log(`Conectado a ${printerDevice.name}`);
      const deviceInfo = await getDeviceInfo();
      console.log('Información del dispositivo:', deviceInfo);
      await printTestPage();
    } else {
      console.error('No se pudo conectar al dispositivo seleccionado.');
    }
  } catch (error) {
    console.error(`Error al seleccionar el dispositivo: ${error.message}`);
    throw error;
  }
}

async function connectToDevice() {
  try {
    console.log('Conectando al dispositivo...');
    printerServer = await printerDevice.gatt.connect();
    console.log('Conexión GATT establecida.');

    try {
      const service = await printerServer.getPrimaryService(PRINTER_SERVICE_UUID);
      printerCharacteristic = await service.getCharacteristic(PRINTER_CHARACTERISTIC_UUID);
      console.log('Característica de impresión SW67 encontrada.');
    } catch (error) {
      console.log('No se encontró el servicio específico de SW67, buscando características genéricas...');
      await findPrintCharacteristic();
    }

    return printerCharacteristic != null;
  } catch (error) {
    console.error(`Error al conectar con el dispositivo: ${error.message}`);
    return false;
  }
}

async function findPrintCharacteristic() {
  try {
    const services = await printerServer.getPrimaryServices();
    for (const service of services) {
      console.log(`Examinando servicio: ${service.uuid}`);
      const characteristics = await service.getCharacteristics();
      for (const characteristic of characteristics) {
        console.log(`Característica encontrada: ${characteristic.uuid}`);
        if (characteristic.properties.write || characteristic.properties.writeWithoutResponse) {
          printerCharacteristic = characteristic;
          console.log(`Característica de impresión seleccionada: ${characteristic.uuid}`);
          return;
        }
      }
    }
    console.error('No se encontró ninguna característica adecuada para imprimir.');
  } catch (error) {
    console.error(`Error al buscar la característica de impresión: ${error.message}`);
    throw error;
  }
}

async function getDeviceInfo() {
  try {
    let deviceInfo = {
      name: printerDevice.name,
      id: printerDevice.id
    };

    try {
      const genericAccessService = await printerServer.getPrimaryService('generic_access');
      const deviceNameChar = await genericAccessService.getCharacteristic('device_name');
      deviceInfo.deviceName = new TextDecoder().decode(await deviceNameChar.readValue());
    } catch (e) {
      console.log('No se pudo obtener el nombre del dispositivo:', e.message);
    }

    try {
      const deviceInfoService = await printerServer.getPrimaryService('device_information');
      const serialNumberChar = await deviceInfoService.getCharacteristic('serial_number_string');
      deviceInfo.serialNumber = new TextDecoder().decode(await serialNumberChar.readValue());
    } catch (e) {
      console.log('No se pudo obtener el número de serie:', e.message);
    }

    return deviceInfo;
  } catch (error) {
    console.error(`Error al obtener información del dispositivo: ${error.message}`);
    return { name: printerDevice.name, id: printerDevice.id };
  }
}

function onDisconnected() {
  console.log('Dispositivo desconectado.');
  printerServer = null;
  printerCharacteristic = null;
}

async function printTestPage() {
  const testData = "\n\n\nESTA ES UNA IMPRESIÓN DE PRUEBA\n\n\n";
  try {
    await printData(testData);
    console.log('Impresión de prueba realizada con éxito.');
  } catch (error) {
    console.error(`Error en la impresión de prueba: ${error.message}`);
    throw error;
  }
}

async function printData(dataToPrint) {
  if (!printerCharacteristic) {
    throw new Error('No hay una característica de impresión seleccionada.');
  }

  try {
    const encoder = new TextEncoder();
    const data = encoder.encode(dataToPrint);

    if (printerCharacteristic.properties.writeWithoutResponse) {
      await printerCharacteristic.writeValueWithoutResponse(data);
    } else if (printerCharacteristic.properties.write) {
      await printerCharacteristic.writeValue(data);
    } else {
      throw new Error('La característica no admite escritura.');
    }

    console.log('Datos enviados correctamente.');
  } catch (error) {
    console.error(`Error al enviar datos: ${error.message}`);
    throw error;
  }
}

function getDevice() {
  return printerDevice;
}

export default {
  selectPrinter,
  printData,
  getDevice,
  printTestPage,
  getDeviceInfo
};
<template>
  <transition name="fade">
    <div v-if="isLoading" class="loading-container">
      <div class="backdrop"></div>
      <div class="loading-content">
        <div class="logo-container">
          <v-img
            :src="logo"
            :alt="'AI Assistant'"
            class="logo"
            contain
            height="120"
            width="120"
          ></v-img>
        </div>
        <div class="progress-container">
          <div class="progress-bar">
            <div class="progress-fill"></div>
          </div>
        </div>
        <transition name="slide-fade" mode="out-in">
          <div :key="currentMessage" class="loading-text">{{ currentMessage }}</div>
        </transition>
        <div class="particles-container">
          <div v-for="n in 20" :key="n" class="particle" :style="particleStyle(n)"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AILoader",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      logo: "",
      messages: [
        "Initializing AI systems...",
        "Processing complex algorithms...",
        "Analyzing data patterns...",
        "Generating intelligent insights...",
        "Optimizing performance...",
      ],
      currentMessageIndex: 0,
      messageInterval: null,
    };
  },
  computed: {
    currentMessage() {
      return this.messages[this.currentMessageIndex];
    },
  },
  methods: {
    particleStyle(index) {
      const size = Math.random() * 4 + 1;
      const speed = Math.random() * 12 + 8;
      const delay = Math.random() * 5;
      const left = Math.random() * 100;
      return {
        width: `${size}px`,
        height: `${size}px`,
        left: `${left}%`,
        animationDuration: `${speed}s`,
        animationDelay: `${delay}s`,
      };
    },
    startMessageCycle() {
      this.messageInterval = setInterval(() => {
        this.currentMessageIndex = (this.currentMessageIndex + 1) % this.messages.length;
      }, 3000);
    },
  },
  mounted() {
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    this.logo = `../sites/${root}/logo.webp`;
    this.startMessageCycle();
  },
  beforeDestroy() {
    if (this.messageInterval) {
      clearInterval(this.messageInterval);
    }
  },
};
</script>

<style scoped>
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #1a237e, #0d47a1);
  opacity: 0.95;
}

.loading-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.logo-container {
  margin-bottom: 2rem;
}

.logo {
  border-radius: 50%;
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.2);
  animation: pulse 2s infinite ease-in-out;
}

.progress-container {
  width: 300px;
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.progress-bar {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #4fc3f7, #2196f3);
  animation: progress 2s infinite ease-in-out;
}

.loading-text {
  font-size: 1.2rem;
  font-weight: 500;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px;
}

.particles-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.particle {
  position: absolute;
  bottom: -10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  animation: float linear infinite;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@keyframes progress {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>

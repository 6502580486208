<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.xsOnly ? '100%' : '400'"
    :height="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar flat>
          <v-btn icon @click="closeChat" class="mr-2">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title class="text-subtitle-1 font-weight-medium">{{
            chatTitle
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="logout">
                <v-list-item-title>Cerrar Sesión</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <div
          class="connection-status text-caption"
          :class="connectionStatus.color + '--text'"
        >
          {{ connectionStatus.text }}
        </div>
        <!-- Formulario de inicio de sesión o área de chat -->
        <div v-if="!userInfo" class="pa-4 login-form">
          <v-form @submit.prevent="submitUserInfo" ref="form" v-model="isFormValid">
            <v-text-field
              v-model="name"
              label="Nombre"
              :rules="[(v) => !!v || 'El nombre es requerido']"
              required
              filled
              dense
            ></v-text-field>
            <v-text-field
              v-model="email"
              label="Correo electrónico"
              type="email"
              :rules="[
                (v) => !!v || 'El correo electrónico es requerido',
                (v) => /.+@.+\..+/.test(v) || 'El correo electrónico debe ser válido',
              ]"
              required
              filled
              dense
            ></v-text-field>
            <v-text-field
              v-model="phone"
              label="Teléfono"
              :rules="[
                (v) => !!v || 'El teléfono es requerido',
                (v) => /^\d{10}$/.test(v) || 'El teléfono debe tener 10 dígitos',
              ]"
              required
              filled
              dense
            ></v-text-field>
            <v-btn
              type="submit"
              color="#075E54"
              dark
              block
              :disabled="!isFormValid"
              class="mt-4"
            >
              Iniciar Chat
            </v-btn>
          </v-form>
        </div>

        <div v-else ref="chatMessages">
          <div
            v-for="(message, index) in messages"
            :key="index"
            :class="[
              'message mb-2 pa-2',
              message.type === 'system'
                ? 'message-system'
                : message.from === 'Usuario'
                ? 'message-sent'
                : 'message-received',
            ]"
          >
            <div class="message-content">
              {{ message.text }}
            </div>
            <div class="message-time caption">
              {{ formatTime(message.timestamp) }}
              <v-icon v-if="message.from === 'Usuario'" small class="ml-1">
                {{ message.read ? "mdi-check-all" : "mdi-check" }}
              </v-icon>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions v-if="userInfo" class="pa-2 chat-input">
        <v-text-field
          v-model="newMessage"
          placeholder="Escribe un mensaje"
          @keyup.enter="sendMessage"
          append-icon="mdi-send"
          @click:append="sendMessage"
          hide-details
          filled
          rounded
          dense
          :disabled="!isAgentAssigned"
          class="message-input"
        >
          <template v-slot:prepend-inner>
            <v-icon color="grey darken-2">mdi-emoticon-outline</v-icon>
          </template>
          <template v-slot:append-outer>
            <v-icon color="grey darken-2">mdi-paperclip</v-icon>
          </template>
        </v-text-field>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ClientChatComponent",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      userInfo: null,
      name: "",
      email: "",
      phone: "",
      messages: [],
      newMessage: "",
      socket: null,
      sessionId: null,
      isFormValid: false,
      isConnected: false,
      isAgentAssigned: false,
      agentName: null,
      notificationSound: new Audio(process.env.BASE_URL + "audio/notification.mp3"),
      isWindowFocused: true,
      isChatFocused: false,
    };
  },
  computed: {
    chatTitle() {
      if (this.agentName) {
        return this.agentName;
      } else if (this.userInfo) {
        return this.userInfo.name;
      } else {
        return "Chat de Soporte";
      }
    },
    connectionStatus() {
      if (this.isConnected) {
        return {
          color: "success",
          text: this.isAgentAssigned ? "en línea" : "conectando...",
        };
      } else {
        return {
          color: "error",
          text: "desconectado",
        };
      }
    },
  },
  methods: {
    async hashEmail(email) {
      const buffer = await crypto.subtle.digest(
        "SHA-256",
        new TextEncoder().encode(email)
      );
      return Array.from(new Uint8Array(buffer))
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
    },
    async submitUserInfo() {
      if (this.$refs.form.validate()) {
        this.userInfo = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          domain: window.location.hostname,
        };
        await this.connectWebSocket();
      }
    },
    async connectWebSocket() {
      this.sessionId = await this.hashEmail(this.email);
      const server = "wss://wss.ai-pos.co:8081";
      this.socket = new WebSocket(server);

      this.socket.onopen = () => {
        console.log("Conexión WebSocket establecida");
        this.isConnected = true;
        this.initializeChat();
        this.addSystemMessage("Conectado al chat de soporte");
      };

      this.socket.onmessage = this.handleWebSocketMessage;

      this.socket.onclose = () => {
        console.log("Conexión WebSocket cerrada");
        this.isConnected = false;
        this.isAgentAssigned = false;
        this.addSystemMessage("Desconectado del chat de soporte");
        setTimeout(() => this.reconnect(), 5000);
      };

      this.socket.onerror = (error) => {
        console.error("Error en la conexión WebSocket:", error);
        this.isConnected = false;
        this.isAgentAssigned = false;
      };
    },
    reconnect() {
      if (!this.isConnected && this.userInfo) {
        console.log("Intentando reconectar...");
        this.connectWebSocket();
      }
    },
    initializeChat() {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(
          JSON.stringify({
            type: "initialize",
            userInfo: this.userInfo,
            sessionId: this.sessionId,
          })
        );
      } else {
        console.error("WebSocket no está abierto. Estado:", this.socket.readyState);
      }
    },
    sendMessage() {
      if (
        this.newMessage.trim() &&
        this.socket &&
        this.socket.readyState === WebSocket.OPEN &&
        this.isAgentAssigned
      ) {
        const message = {
          type: "client_message",
          message: {
            text: this.newMessage,
          },
        };
        this.socket.send(JSON.stringify(message));
        this.messages.push({
          from: "Usuario",
          text: this.newMessage,
          timestamp: new Date(),
          read: false,
        });
        this.newMessage = "";
        this.scrollToBottom();
      }
    },
    addSystemMessage(text) {
      this.messages.push({
        type: "system",
        text: text,
        timestamp: new Date(),
      });
      this.scrollToBottom();
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatMessages = this.$refs.chatMessages;
        if (chatMessages) {
          chatMessages.scrollTop = chatMessages.scrollHeight;
        }
      });
    },
    closeChat() {
      if (this.socket) {
        this.socket.close();
      }
      this.$emit("close");
    },
    logout() {
      if (this.socket) {
        this.socket.close();
      }
      this.userInfo = null;
      this.name = "";
      this.email = "";
      this.phone = "";
      this.messages = [];
      this.sessionId = null;
      this.isConnected = false;
      this.isAgentAssigned = false;
      this.agentName = null;
    },
    formatTime(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    },
    playNotificationSound() {
      if (!this.isWindowFocused || !this.isChatFocused) {
        this.notificationSound.play().catch((error) => {
          console.error("Error al reproducir el sonido de notificación:", error);
        });
      }
    },
    handleWindowFocus() {
      this.isWindowFocused = true;
    },
    handleWindowBlur() {
      this.isWindowFocused = false;
    },
    handleChatFocus() {
      this.isChatFocused = true;
    },
    handleChatBlur() {
      this.isChatFocused = false;
    },
    handleWebSocketMessage(event) {
      const data = JSON.parse(event.data);
      console.log("Mensaje recibido:", data);

      switch (data.type) {
        case "session_initialized":
          console.log("Sesión inicializada con ID:", this.sessionId);
          break;
        case "agent_assigned":
          this.isAgentAssigned = true;
          this.agentName = data.agentName;
          this.addSystemMessage(`${this.agentName} se ha unido al chat`);
          break;
        case "agent_message":
          const agentFirstName = data.agentName ? data.agentName.split(" ")[0] : "Agente";
          this.messages.push({
            from: agentFirstName,
            text: data.message.text,
            timestamp: new Date(),
          });
          this.scrollToBottom();
          this.playNotificationSound(); // Ahora solo sonará si no está enfocado
          break;
        case "agent_disconnected":
          this.isAgentAssigned = false;
          this.addSystemMessage("El asesor se ha desconectado");
          this.agentName = null;
          break;
      }
    },
  },
  mounted() {
    window.addEventListener("focus", this.handleWindowFocus);
    window.addEventListener("blur", this.handleWindowBlur);
    if (this.socket) {
      this.socket.onmessage = this.handleWebSocketMessage;
    }
  },
  beforeDestroy() {
    window.removeEventListener("focus", this.handleWindowFocus);
    window.removeEventListener("blur", this.handleWindowBlur);
    if (this.socket) {
      this.socket.close();
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.handleChatFocus();
        });
      } else {
        this.handleChatBlur();
      }
    },
  },
};
</script>

<style scoped>
.connection-status {
  text-align: center;
  padding: 4px;
  font-size: 0.75rem;
  background-color: #f0f0f0;
}

.login-form {
  background-color: white;
}

.chat-messages {
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.message {
  max-width: 75%;
  margin-bottom: 8px;
  padding: 8px 12px;
  border-radius: 7.5px;
  position: relative;
  clear: both;
  z-index: 1;
}

.message-sent {
  float: right;
  background-color: #dcf8c6;
  border-top-right-radius: 0;
}

.message-received {
  float: left;
  background-color: #ffffff;
  border-top-left-radius: 0;
}

.message-system {
  width: 100%;
  text-align: center;
  background-color: #e1f3fb;
  color: #075e54;
  font-style: italic;
  font-size: 0.85em;
  margin: 8px 0;
  padding: 5px 10px;
}

.message-content {
  margin-bottom: 4px;
}

.message-time {
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.45);
  text-align: right;
}

.chat-input {
  background-color: #f0f0f0;
  border-top: 1px solid #d1d1d1;
}

.message-input {
  border-radius: 20px !important;
}

/* Estilos para dispositivos móviles */
@media (max-width: 600px) {
  .chat-container {
    height: 100vh;
  }

  .message {
    max-width: 85%;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"width":_vm.$vuetify.breakpoint.xsOnly ? '100%' : '400',"height":_vm.$vuetify.breakpoint.xsOnly ? '100%' : '600',"transition":"dialog-bottom-transition","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":_vm.closeChat}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v(_vm._s(_vm.chatTitle))]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-title',[_vm._v("Cerrar Sesión")])],1)],1)],1)],1)],1),_c('v-card-text',[_c('div',{staticClass:"connection-status text-caption",class:_vm.connectionStatus.color + '--text'},[_vm._v(" "+_vm._s(_vm.connectionStatus.text)+" ")]),(!_vm.userInfo)?_c('div',{staticClass:"pa-4 login-form"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submitUserInfo($event)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-text-field',{attrs:{"label":"Nombre","rules":[function (v) { return !!v || 'El nombre es requerido'; }],"required":"","filled":"","dense":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"label":"Correo electrónico","type":"email","rules":[
              function (v) { return !!v || 'El correo electrónico es requerido'; },
              function (v) { return /.+@.+\..+/.test(v) || 'El correo electrónico debe ser válido'; } ],"required":"","filled":"","dense":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"label":"Teléfono","rules":[
              function (v) { return !!v || 'El teléfono es requerido'; },
              function (v) { return /^\d{10}$/.test(v) || 'El teléfono debe tener 10 dígitos'; } ],"required":"","filled":"","dense":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('v-btn',{staticClass:"mt-4",attrs:{"type":"submit","color":"#075E54","dark":"","block":"","disabled":!_vm.isFormValid}},[_vm._v(" Iniciar Chat ")])],1)],1):_c('div',{ref:"chatMessages"},_vm._l((_vm.messages),function(message,index){return _c('div',{key:index,class:[
            'message mb-2 pa-2',
            message.type === 'system'
              ? 'message-system'
              : message.from === 'Usuario'
              ? 'message-sent'
              : 'message-received' ]},[_c('div',{staticClass:"message-content"},[_vm._v(" "+_vm._s(message.text)+" ")]),_c('div',{staticClass:"message-time caption"},[_vm._v(" "+_vm._s(_vm.formatTime(message.timestamp))+" "),(message.from === 'Usuario')?_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(message.read ? "mdi-check-all" : "mdi-check")+" ")]):_vm._e()],1)])}),0)]),(_vm.userInfo)?_c('v-card-actions',{staticClass:"pa-2 chat-input"},[_c('v-text-field',{staticClass:"message-input",attrs:{"placeholder":"Escribe un mensaje","append-icon":"mdi-send","hide-details":"","filled":"","rounded":"","dense":"","disabled":!_vm.isAgentAssigned},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMessage($event)},"click:append":_vm.sendMessage},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":"grey darken-2"}},[_vm._v("mdi-emoticon-outline")])]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"color":"grey darken-2"}},[_vm._v("mdi-paperclip")])]},proxy:true}],null,false,2293905645),model:{value:(_vm.newMessage),callback:function ($$v) {_vm.newMessage=$$v},expression:"newMessage"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!-- <v-snackbar
    bottom
    left
    :value="deferredPrompt"
    width="150px"
    :timeout="-1"
    color="transparent"
    elevation="0"
  > -->
  <!-- <v-btn small dark @click="clickCallback"> Instalar APP </v-btn> -->
  <!-- </v-snackbar> -->

  <v-snackbar bottom right v-if="deferredPrompt" :timeout="-1" color="success">
    <v-btn large dark color="info" ref="addBtn" @click="clickCallback">
      Instalar APP
      <v-icon right dark>mdi-android</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: "AddToHomeScreen",
  data: () => ({
    deferredPrompt: null,
  }),
  mounted() {
    this.captureEvent();
  },
  methods: {
    captureEvent() {
      window.addEventListener("beforeinstallprompt", (e) => {
        // ! Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
      });
    },
    clickCallback() {
      // Show the prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // Call another function?
        }
        this.deferredPrompt = null;
      });
    },
  },
};
</script>
